.reviews-section {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 20px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.reviews-list {
  list-style: none;
  padding: 0;
}

.review-item {
  background-color: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.review-item h3 {
  margin: 0 0 10px;
}

.review-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.review-form input,
.review-form textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.review-form button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.review-form button:hover {
  background-color: #0056b3;
}
