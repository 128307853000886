/* App.css */
h2,h1,h3 {
   font-family: 'Montserrat', sans-serif;
}

p {
    font-family: 'Quicksand', sans-serif;

}

body {
  font-family: 'Arial', sans-serif;
}

.App {
  text-align: center;
}

nav h1 {
  margin: 0;
}

nav ul {
  list-style: none;
  display: flex;
}

nav li {
  margin: 0 10px;
  cursor: pointer;
}

h2 {
  color: #333;
}

.feature {
  margin-bottom: 20px;
}

.b-example-divider {
    height: 5rem;
}

body {
      font-family: Arial, sans-serif;
      /*background-color: #f8f9fa;*/
      background-color: #fffff;
}

.banner {
      background-color: #343a40;
      color: #ffffff;
      display: flex;
      align-items: center;
      min-height: 100vh;
}

.banner .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Ensure column direction on smaller screens */
}

    .banner h1 {
      font-size: 2.8rem;
    }

    .banner p {
      font-size: 1.3rem;
    }

    /* Card Styling */
.card {
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /*min-height: 350px;  Adjust the value as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  min-height: 30vh; /* Responsive height */
  /* width: 25vw; /* Responsive width */
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.card-title {
  font-size: 1.7rem;
  color: #343a40;
  transition: color 0.3s ease;
}

.card:hover .card-text {
  opacity: 1;
  transform: translateY(0);
}

.card-text {
  font-size: 1.2rem;
  color: #333;
  /*opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;*/
}

.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 102, 204, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.card:hover::before {
  opacity: 1;
}

.homecards {
  /*margin: auto; Center the card within the column */
}

/* News Panel */
.news-section {
  display: flex;
  align-items: center;
  min-height: 80vh;
}
.news-panel {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: 100%; /* Ensure it takes full height */
}

.news-panel h3 {
  color: #66b2b2;
  transition: color 0.3s ease;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.news-panel ul {
  list-style: none;
  padding: 0;
  flex-grow: 1; /* Make the list take up remaining space */
}

.news-panel ul li a {
  color:  #000;
  text-decoration: none;
  transition: color 0.3s ease;
}

.news-panel ul li {
  margin-bottom: 10px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.news-panel ul li a:hover {
  text-decoration: underline;
}

.news-panel:hover ul li {
  opacity: 1;
  transform: translateY(0);
}

.news-panel::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 102, 204, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.news-panel:hover::before {
  opacity: 1;
}

@media (max-width: 768px) {
  .carousel-caption h5 {
    font-size: 1.25rem;
  }

  .carousel-caption p {
    font-size: 0.875rem;
  }

  .carousel-caption {
    height: 50%;
  }

}

/* Team Section */
.team-member {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.team-member h3 {
  color: #66b2b2;
  transition: color 0.3s ease;
}

.team-member:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.team-member p {
  color: #333;
}

.team-image {
  height: 300px; /* Set a fixed height for all images */
  width: 100%; /* Ensure the image takes the full width of the container */
  object-fit: cover; /* This ensures the image maintains its aspect ratio while covering the entire container */
  margin-bottom: 15px; /* Add some space between the image and the text */
}

/* Call to Action Section */
.testimonials-section {
  color: #fff;
}

.testimonials-section h2 {
  font-size: 2rem;
}

.testimonials-section p {
  font-size: 1.2rem;
}

.testimonials-section .btn {
  background-color: #fff;
  color: #66b2b2;
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.testimonials-section .btn:hover {
  background-color: #f8f9fa;
  color: gray;
}

.bike-fit-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 70vh;
}

.bike-fit-images {
  /*display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  flex-wrap: wrap;*/
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.bike-fit-image {
  /*width: 100%;
  margin: 0 10px;
  border-radius: 10px;
  min-height: 30vh;*/
  max-width: 100%;
  height: auto;
  min-height: 25vw;
  margin: 10px;
}

.bike-fit-info {
  max-width: 500px;
  margin-left: 20px;
  text-align: left;
}

.bike-fit-info h2 {
  color: #66b2b2;
}

.bike-fit-info h1 {
  font-size: 2.5em;
  margin: 10px 0;
}

.bike-fit-info p {
  margin: 20px 0;
}

.bike-fit-buttons {
  display: flex;
  gap: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .bike-fit-container {
    flex-direction: column;
    align-items: center;
  }

  .bike-fit-images {
    flex-direction: column;
  }

  .bike-fit-info {
    text-align: center;
  }
}

@media (max-width: 576px) {
  .bike-fit-info h1 {
    font-size: 1.5rem;
  }

  .bike-fit-info p {
    font-size: 1.2rem;
  }

  .get-started-button {
    width: 100%;
    padding: 15px;
  }
}

.get-started-button, .learn-more-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.get-started-button {
  background-color: #66b2b2;
  color: white;
}

.learn-more-button {
  background-color: transparent;
  color: #ff6600;
  border: 2px solid #ff6600;
}

.image-section {
    position: relative;
    flex: 1;
    overflow: hidden;
}

.background-image {
    width: 100%;
    height: 80vh;

}

.text-overlay {
    position: absolute;
    top: 75%;
    right: 25%;
    transform: translate(100%, -100%);
    color: black;
    text-align: center;
    z-index: 1;
    /*text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: Add text shadow for better readability */
}

.overlay-image {
    width: 20vw;
    height: auto;
    position: absolute;
    transform: translate(-150%, -70%);
}

.overlay-image2 {
    width: 20vw;
    height: auto;
    position: absolute;
    transform: translate(50%, 0%);
}

.overlay-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
}
.b-example-divider {
    height: 6rem;
    /*background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, .1), inset 0 0.125em 0.5em rgba(0, 0, 0, .15);*/
}

/*
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
}

ul {
  position: relative;
  width: 300px;
}

li {
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 15px 18px;
  background: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  cursor: grab;
}

li svg {
  width: 18px;
  height: 18px;
  cursor: grab;
}*/