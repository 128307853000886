/* Add the gradient background to the kin-container */
.kin-container {
  background: linear-gradient(to bottom, #000000, #72c6cf); /* Example gradient */
  min-height: 100vh; /* Make sure it covers the whole viewport height */
  padding-bottom: 250vh;
}


* {
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: input-mono, monospace;
  font-weight: 400;
  font-style: normal;
}

/*
footer {
  max-height:10vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background-image: radial-gradient(
    rgba(0, 0, 0, 0) 1px,
    var(--background) 1px
  );
  background-size: 4px 4px;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  -moz-backdrop-filter: blur(3px);
  font-size: 14px;
  line-height: 14px;
}

footer::before {
  display: block;
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--accent);
  opacity: 0.2;
}

footer svg {
  margin-right: 20px;
}

footer a {
  text-decoration: none;
  color: var(--accent);
}*/

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: white;
}

::-webkit-scrollbar-thumb {
  background: white;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: white;
}

html {
  scroll-snap-type: y mandatory;
}

.h1 {
  font-size: 56px;
  font-weight: 700;
  letter-spacing: -3px;
  line-height: 1.2;
  text-align: center;
  margin: 100px 0;
}

.parallax-title {
  margin: 0;
  color: white;
  left: calc(50% + 20vw);
  font-size: 56px;
  font-weight: 700;
  letter-spacing: -3px;
  line-height: 1.2;
  position: absolute;
}

.parallax-text {
  margin-top: 15vh;
  color: white;
  left: calc(55% + 10vw);
  font-size: 20px;
  font-weight: 200;
  line-height: 1.2;
  position: absolute;
}


.sec {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-align: center;
  perspective: 500px;
}

.secDiv {
  /*width: 300px;
  height: 550px;*/
  width: 35vw;
  height: 75vh;
  position: relative;
  max-height: 90vh;
  margin: 20px;
  /*background: white;*/
  overflow: hidden;
}

.scrollImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60%;
  height: 100%;
}

.scrollImg.custom-size1 {
position: absolute;
  top: 0;
  left: -15%;
  right: 0;
  bottom: 0;
  width: 105%;
  height: 90%;
}

.scrollImg.custom-size2 {
position: absolute;
  top: 0;
  left: 0;
  right: 10%;
  bottom: 0;
  width: 100%;
  height: 90%;
}

.progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  background: white;
  bottom: 100px;
}


/* Media queries for responsive design */
@media (max-width: 768px) {
  .secDiv {
    height: 60%;
    width:80%;
    padding: 10px;
  }

  .parallax-title {
    font-size: 1.5rem;
    top: 25%;
  }

  .parallax-text {
    font-size: 0.9rem;
    top: 15%;
  }

  .scrollImg.custom-size1,
  .scrollImg.custom-size2 {
    width: 70%; /* Adjust custom sizes for smaller screens */
  }
}

@media (max-width: 480px) {
  .parallax-title {
    font-size: 1.2rem;
  }

  .parallax-text {
    font-size: 0.8rem;
  }

  .scrollImg.custom-size1,
  .scrollImg.custom-size2 {
    width: 90%; /* Adjust custom sizes for very small screens */
  }
}
