/* src/VideoUploader.css */
h2,h1,h3 {
   font-family: 'Montserrat', sans-serif;
}

p {
    font-family: 'Quicksand', sans-serif;

}

.bannerCards {
    background-image: linear-gradient(to right, #000000, #72c6cf);
      color: #ffffff;
      display: flex;
      align-items: center;
      min-height: 70vh;
}

.bannerCards .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Ensure column direction on smaller screens */
}

.video-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  min-height: 300px;
  margin: 0 auto;
}

.video-uploader h2 {
  margin: 20px 0;
  font-size: 24px;
  color: #333;
}

.carder {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  min-height: 35vh;
  /*width: 100%;*/
  min-width:70vw;
  max-width: 600px;
  margin-bottom: 20px;
  animation: float 0.6s ease-in-out forwards;
}

@keyframes float {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.video-container,
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height:50vh;
}

video {
  width: 80%;
  height: auto;
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.image-container {
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.uploaded-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


#progress {
  /*position: fixed;*/
  top: 200px;
  left: 20px;
  transform: rotate(-90deg);
}

circle {
  stroke-dashoffset: 0;
  stroke-width: 15%;
  fill: none;
}

.bg {
  stroke: white;
  opacity: 0.3;
}

#progress .indicator {
  stroke: white;
}

.anul {
  display: flex;
  list-style: none;
  height: 300px;
  overflow-x: scroll;
  padding: 15px 0;
  flex: 0 0 600px;
  margin: 0 auto;
  min-width: 60vw;
  max-width:80vw;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: #fff3;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: white;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: #fff3;
}

.anli {
  flex: 0 0 200px;
  background: white;
  margin: 0 20px 0 0;
  overflow: hidden; /* Ensure the image doesn't overflow the li */
  display: flex;
  min-width: 500px; /* Adjust the width to your desired value */
  flex-direction: column; /* Arrange images in a column */
  justify-content: space-between; /* Space out the images */
  align-items: center; /* Center the images horizontally */
  position: relative;
  border-radius: 10px;
}

.anli:last-of-type {
  margin: 0;
}

.anli img {
  padding:10px;
  max-width: 100%; /* Make the image fill the width of the container */
  max-height: 75%;
  border-radius: 20px;
  /* height: 70%; Maintain aspect ratio */
}
.anli p {
  margin: 1px 0;
  color: black;
  text-align: center; /* Center text */
}

.text-container {
  padding: 10px;
  min-width: 80%;
  text-align: center;
  flex-grow: 1; /* Ensures the text container grows with the card */
  display: flex;
  flex-direction: column;
}

.text-container h3 {
  /*margin-bottom: 10px;*/
  font-size: 1.5em; /* Adjust the font size as needed */
  color: #333; /* Adjust the color as needed */
}

.text-container ul {
  margin: 0;
  padding: 0;
  text-align: left;
  flex-grow: 1;
}

.text-container ul li {
  /*margin-bottom: 5px;*/
  font-size: 1em;; /* Adjust the font size as needed */
  color: #666; /* Adjust the color as needed */
}

/* AnalyticsPanel.css */
.analytics-panel {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.analytics-card {
  background: #123;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 200px;
  text-align: center;
}

.anal-card-content h4 {
  font-size: 1rem;
  margin: 0;
}

.anal-card-content h1 {
  font-size: 2rem;
  margin: 10px 0;
}

.anal-card-content p {
  font-size: 0.8rem;
  margin: 0;
  color: #ccc;
}

.scrollable-list {
    max-height: 100px; /* Adjust the height as needed */
    min-width: 80%;
    overflow-y: auto;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }

.download-button {
  /*margin-top: 15px;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;*/
  position: fixed;
  bottom: 20px;
  right: 80px;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3; /* Darker shade of primary color */
  transform: scale(1.05);
}

.download-button:active {
  background-color: #003f7f; /* Even darker shade of primary color */
  transform: scale(1);
}

.help-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: grey;
  color: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.help-button:hover {
  background-color: #0056b3; /* Darker shade of primary color */
  transform: scale(1.05);
}

.help-button:active {
  background-color: #003f7f; /* Even darker shade of primary color */
  transform: scale(1);
}

.textarea-styled {
  width: 100%;
  height: 45%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.textarea-styled:focus {
  border-color: #007BFF;
}


.paste-area {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.paste-area img {
  max-width: 100%;
  max-height: 400px;
  margin-top: 20px;
}

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

label {
  font-size: 18px;
  margin-bottom: 10px;
}

.slider {
  width: 80%;
  max-width: 400px;
}

.carImg {
  background: white;
  margin: 0 20px 0 0;
  display: flex;
  flex-direction: column; /* Arrange images in a column */
  justify-content: center; /* Space out the images */
  align-items: center; /* Center the images horizontally */
  border-radius: 10px;
}

.carousel-image {
  object-fit: contain;
  width: 100%;
  max-height: 50vh;
  justify-content: center;
  align-items: center; /* Center the images horizontally */
}

.buttonStyle{
  padding: '10px 20px';
  margin: '10px';
  font-size: '16px';
  cursor: 'pointer';
  background-color: '#007BFF';
  color: '#fff';
  border: 'none';
  border-radius: '5px'
}
