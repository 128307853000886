.min-vh-100 {
    min-height: 100vh;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.full-height {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.min-width-card {
    min-width: 50vw; /* Set the minimum width to 50% of the viewport width */
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.text-button {
    background: none;
    border: none;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px; /* Adjust font size as needed */
    font-family: inherit; /* Use the same font as the surrounding text */
    padding: 0;
    margin: 0;
}

.text-button:hover {
    text-decoration: none; /* Optional: Remove underline on hover */
    color: darkblue; /* Optional: Change color on hover */
}

.custom-form {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
}