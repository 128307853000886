.video-editor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f5f5f5;
}

.video-container {
  width: 80%;
  height: auto;
}

.video-player {
    width: 40vw;
    max-width: 800px;
    margin-bottom: 20px;
}

.App {

}